
.toggle-view-icon {
    height: 24px;
}

.notion_diary_logo {
    fill: black;
}

.dark {
    .notion_diary_logo {
        fill: white;
    }
}


