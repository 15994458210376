@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/dist/css/bootstrap.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import "./themes/dark-theme.scss";
@import "./themes//default-theme.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit !important;
}

.cta {
  background-color: #6031db;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 0;

}

.no-border {
  border:0;
}

.cta-small {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 13px;
}

.cta-large {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-size: 16px;
}

.icon {
  height: 24px;
}

.cover-img {
  height: 200px;
  background-position: center;
  background-size: cover;
}

.cursor {
  cursor: pointer;
}

.page-content {
  a {
    color: blueviolet !important;
  }
  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  figcaption {
    text-align: center;
    opacity: 0.5;
    margin-bottom: 1rem;
  }
}

.loading-screen {
  height: 80vh !important;
}




.np-container {
  width: 60%;
  margin: auto;
}

@media screen and (max-width: 764px) {
  .np-container {
    width: 90%;
  }
  .np-row {
    flex-direction: column-reverse;
    div {
      width: 100%;
      margin-top: 10px;
    }
  }
}



// .ant-input {
//   border-radius: 10px !important;
//   border-top-left-radius: 0 !important;
//   border-bottom-left-radius: 0 !important;
// }

.anticon {
  transform: scale(1.5);
}

.smaller-icon {
  transform: scale(1);
}


.w-80 {
  width: 80%;
}

.w-60 {
  width: 60%;
}

.homepage-title {
  // color: #d5c6ff !important;
  font-weight: 500 !important;
  span {
    font-weight: inherit !important;
  }
}



.mt-5 {
  margin-top: 2rem;
}

.mb-5 {
  margin-bottom: 2rem;
}

.mt-8 {
  margin-top: 3.5rem;
}


.pt-13 {
  padding-top: 6.5rem;
}

.h-inherit {
  min-height: inherit;
}

.app-wrapper {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 82px auto 100px;
}

.h-full {
  height: 100%;
}




.animated-cta {
  border: 0;
  background: -webkit-linear-gradient(left, $DarkThemeDarkBlueColor, $BlueColor);

  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.blog-card-placeholder {
  animation: none;
  background-color: #7578E5;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.mt-5 {
  margin-top: 2rem;
}


.ps-32px {
  padding-left: 32px;
}


.blog-content {
  margin-top:64px;
}

.relative {
  position: relative;
}


.web-feature-sub-point-heading {
  font-weight: 600;
  color: #444BD3 !important;
}


.notion_book_mark  {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 20px;
}