// ----------------------- Variables

$DarkThemeDarkBlueColor: #2e2745;
$DarkThemeBlueColor: #6031db;
$DarkThemePrimaryFontColor: #ffffff;
$DarkThemeSecondaryBackgroundColor: #f9f4ff;
$DarkThemeSecondaryFontColor: #9a74ff;
$DarkThemeFooterBackground: #FFFFFF;
$DarkThemePopupColor: #2a243d;
$DarkThemePrimaryBackgroundColor: #121127;

// ----------------------- Styling

.dark {
  background-color: $DarkThemePrimaryBackgroundColor;
  color: $DarkThemePrimaryFontColor;
  .np-blog-card {
    background-color: $DarkThemePopupColor !important;
    box-shadow: 0px 20px 50px  rgba(42, 36, 61,.1);
    color: $DarkThemePrimaryFontColor !important;
  }
  .np-blog-card-2 {
    background-color: $DarkThemePopupColor !important;
    color: $DarkThemePrimaryFontColor !important;
  }

  .np-navbar {
    background-color: $DarkThemePrimaryBackgroundColor !important;
    color: $DarkThemePrimaryFontColor !important;
  }

  .np-second-navbar {
    background-color: $DarkThemeBlueColor !important;
    color: $DarkThemeSecondaryFontColor !important;
  }

  .np-footer {
    color: black !important;
    background-color: $DarkThemeFooterBackground !important;
  }

  .np-divider {
    border-top-color: rgba($color: #FFFFFF, $alpha: 0.12) !important;
  }



  .ant-form-item-label > label {
    color: $DarkThemePrimaryFontColor !important; 
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content  > .ant-steps-item-title{
    color: $DarkThemePrimaryFontColor !important; 
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content  > .ant-steps-item-title{
    color: $DarkThemePrimaryFontColor !important; 
  }

  .ant-steps-item-wait  {
    .ant-steps-item-container > .ant-steps-item-content  > .ant-steps-item-title{
      color: $DarkThemePrimaryFontColor !important; 
  }
  .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: $DarkThemePrimaryFontColor !important ;
  }
 
  }

  .ant-form {
    color: $DarkThemePrimaryFontColor !important; 
    color: $DarkThemePrimaryFontColor;
  }


  .ant-input {
    background-color: transparent !important;
    color: $DarkThemePrimaryFontColor !important;
  }
  .buyMeACoffee {
    background-color: $DarkThemePopupColor;
  }




  .web-features {
        background-color: $DarkThemePrimaryFontColor;
        color: $DarkThemePrimaryBackgroundColor;
    }


  .menu-hamburger {
      fill: $DarkThemePrimaryFontColor;
  }

  .notion_book_mark {
    background-color: $DarkThemePopupColor !important;
    color: $DarkThemePrimaryFontColor !important;

  }
}

// ----------------------- Media Queries
