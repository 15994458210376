@import url("https://fonts.googleapis.com/css?family=Cabin+Sketch");

.error-page {
  h1 {
    font-family: "Cabin Sketch", cursive;
    font-size: 3em;
    text-align: center;
    opacity: 0.8;
    order: 1;
  }

  h1 small {
    display: block;
  }

  height: 85vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}
