@import "../../themes/dark-theme.scss";

.layout-box {
    background-color: #F1F1F8;
    display: flex;
    justify-content: center;
    // align-items: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
    height: 100%;
}

.layout-inner-box {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.layout-content {
    width: 40rem;
}


.dark {
    .layout-box {
        background-color: $DarkThemePrimaryBackgroundColor !important;
    }
}


@media screen and (max-width: 680px) {
     .layout-content {
         width: 30rem;
     }
}

@media screen and (max-width: 500px) {
    .layout-content {
        width: 25rem;
    }
}

@media screen and (max-width: 450px) {
    .layout-content {
        width: 20rem;
    }
}