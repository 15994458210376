
@import "./dark-theme.scss";
// ----------------------- Variables


$DarkBlueColor: #2E2745;
$BlueColor: #6031DB;
$PrimaryFontColor: #241F34;
$SecondaryBackgroundColor: #F9F4FF;
$SecondaryFontColor: #FFFFFF;
$FooterBackground: #F1F1F1;


$PrimaryBackgroundColor: #FFFFFF;

// ----------------------- Styling




.np-blog-card {
    border-radius: 12px;
    border: 1px solid transparent;
    box-shadow: 0px 20px 50px rgba(18, 17, 39,.12);
    // background-color: $SecondaryBackgroundColor !important;
    color: $PrimaryFontColor !important;
    img {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

}


.np-navbar {
    background-color: $PrimaryBackgroundColor !important;
    color: $PrimaryFontColor !important;

}

.np-second-navbar {
    background-color: $BlueColor !important;
    color: $SecondaryFontColor !important;
}

.np-footer {
    background-color: $FooterBackground !important;
}

.np-divider {
    border-top-color: rgba($color: #121127, $alpha: 0.12) !important;
}


.np-button {
    background: $BlueColor !important;
    border-color: $BlueColor !important;
    color: $SecondaryFontColor !important;
}


.buyMeACoffee {
    background-color: $BlueColor;
    color: $DarkThemePrimaryFontColor !important; 
  }


.web-features {
    background-color: $DarkThemePrimaryBackgroundColor;
    color: $DarkThemePrimaryFontColor;
}

.menu-hamburger {
    fill: $PrimaryFontColor;
}

.notion_book_mark {
    background-color: $SecondaryBackgroundColor;
}
// ----------------------- Media Queries
